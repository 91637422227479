import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const checkNoteList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'checknote/list',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('get check note list error: ', err)

      return {}
    })

  return response
}
const checkNoteGet = async id => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'checknote',
      param: id,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('get check note error: ', err)

      return {}
    })

  return response
}
const checkNoteAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'checknote',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('add check note error: ', err)

      return {}
    })

  return response
}

const checkNoteUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'checknote',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('update check note error: ', err)

      return {}
    })

  return response
}
const checkNoteRemove = async id => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'checknote',
      param: id,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('get check note list error: ', err)

      return {}
    })

  return response
}
export default {
  checkNoteList,
  checkNoteGet,
  checkNoteAdd,
  checkNoteUpdate,
  checkNoteRemove,
}
