import checkNote from '@/api/examination/checkNote'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import { onMounted, ref, watch } from '@vue/composition-api'

export default function useCheckNote() {
  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const segmentId = ref(0)
  const totalPage = ref(0)
  const options = ref({})
  const loading = ref(false)
  const columns = ref([
    {
      text: '#',
      align: 'start',
      width: 30,
      value: 'note_id',
    },
    {
      text: i18n.t('suggestion'),
      value: 'note_name',
      width: 500,
    },
    {
      text: i18n.t('option'),
      value: 'actions',
      align: 'center',
      width: 120,
    },
  ])
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)

  const searchtext = ref('')
  const isAddNewCheckNote = ref(false)

  const isEditCheckNote = ref(false)
  const dataEdit = ref(null)

  const isDelete = ref(false)
  const note_id = ref('')

  const { checkNoteList } = checkNote
  onMounted(() => {
    fetchDataTable()
  })

  const fetchDataTable = event => {
    loading.value = true
    checkNoteList({
      searchtext: searchtext.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    }).then(async res => {
      const { count, data, segment, count_of_page } = await res
      dataTableList.value = data
      totalPage.value = count_of_page
      totalDataTableList.value = count
      segmentId.value = segment
      if (event == 'add') {
        options.value.page = await count_of_page
      } else if (event == 'delete') {
        if (options.value.page > count_of_page) {
          options.value.page = count_of_page
          fetchDataTable()
        }
      }
      loading.value = false
    })
  }

  watch([searchtext, options], (newvalue, oldvalue) => {
    loading.value = true
    if (newvalue[0] !== oldvalue[0]) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  return {
    isDelete,
    note_id,
    isEditCheckNote,
    dataEdit,
    isAddNewCheckNote,
    totalPage,
    columns,
    dataTableList,
    totalDataTableList,
    segmentId,
    options,
    loading,
    footer,
    searchtext,
    fetchDataTable,
  }
}
