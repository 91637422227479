<template>
  <div>
    <v-dialog
      v-model="isDelete"
      persistent
      max-width="350"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            color="warning"
            class="me-1"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon> {{ $t('confirm_delete_suggestion') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :loading="loading"
            :disabled="loading"
            @click="removeCheckNote"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="$emit('update:is-delete', false)"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiAlertOutline } from '@mdi/js'
import checkNote from '@/api/examination/checkNote'
import store from '@/store'

export default {
  model: {
    prop: 'isDelete',
    event: 'update:is-delete',
  },
  props: {
    isDelete: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',

    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const { checkNoteRemove } = checkNote

    const removeCheckNote = async () => {
      loading.value = true
      await checkNoteRemove(props.id).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
      })
      loading.value = false
      emit('update:is-delete', false)
      emit('onDelete')
    }

    return {
      loading,
      removeCheckNote,
      icons: {
        mdiAlertOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
