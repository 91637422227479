<template>
  <div>
    <v-dialog
      v-model="isAddNewCheckNote"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('add_suggestion') }}
        </v-card-title>
        <v-form
          ref="formAddCheckNote"
          @submit.prevent="createCheckNote"
        >
          <v-card-text>
            <v-textarea
              v-model="note_name"
              autofocus
              rows="3"
              :label="$t('suggestion')"
              :rules="[required]"
              outlined
              dense
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-add-new-check-note', false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import checkNote from '@/api/examination/checkNote'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewCheckNote',
    event: 'update:is-add-new-check-note',
  },
  props: {
    isAddNewCheckNote: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const formAddCheckNote = ref(false)
    const note_name = ref('')
    const loading = ref(false)

    const { checkNoteAdd } = checkNote

    const createCheckNote = () => {
      const isFormValid = formAddCheckNote.value.validate()
      if (!isFormValid) return
      loading.value = true
      checkNoteAdd({
        note_name: note_name.value,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-add-new-check-note', false)
        loading.value = false
        note_name.value = ''
        emit('onAdd')
      })
    }

    watch(() => props.isAddNewCheckNote, val => {
      if (val) {
        note_name.value = ''
      }
    })

    return {
      loading,
      formAddCheckNote,
      note_name,
      required,
      createCheckNote,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
