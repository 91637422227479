<template>
  <div>
    <v-card>
      <v-card-title>
        {{ $t('CheckNote') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="d-none d-md-block"
          @click="isAddNewCheckNote = true"
        >
          <v-icon class="me-1">
            {{ icons.mdiPlus }}
          </v-icon> {{ $t('add_suggestion') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          fab
          icon
          outlined
          @click="isAddNewCheckNote = true"
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2 mb-2">
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-text-field
            v-model="searchtext"
            :label="$t('search')"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :server-items-length="totalDataTableList"
        :footer-props="footer"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template #[`item.note_id`]="{ index }">
          {{ segmentId + index + 1 }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="dataEdit = item; isEditCheckNote = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            {{ $t("edit") }}
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="note_id = item.note_id;isDelete=true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
              </v-btn>
            </template>
            {{ $t("delete") }}
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data="options.page"
        :page-count="totalPage"
        :segment-id="+segmentId "
        :count-list="dataTableList.length"
        :total="+totalDataTableList"
        @pageChanged="(page)=>{options.page = page ; fetchDataTable()}"
        @itemChanged="(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}"
      />
    </v-card>
    <AddNewCheckNote
      v-model="isAddNewCheckNote"
      @onAdd="fetchDataTable('add')"
    />
    <EditCheckNote
      v-model="isEditCheckNote"
      :data-edit="dataEdit"
      @onUpdate="fetchDataTable('update')"
    />
    <RemoveCheckNote
      :id="note_id"
      v-model="isDelete"
      @onDelete="fetchDataTable('delete')"
    />
  </div>
</template>

<script>
import { mdiPlus, mdiPencilOutline, mdiDeleteOutline } from '@mdi/js'
import useCheckNote from './useCheckNote'
import AddNewCheckNote from './AddNewCheckNote.vue'
import EditCheckNote from './EditCheckNote.vue'
import RemoveCheckNote from './RemoveCheckNote.vue'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  components: {
    AddNewCheckNote,
    EditCheckNote,
    RemoveCheckNote,
    Pagination,
  },
  setup() {
    return {

      ...useCheckNote(),
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
