var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('CheckNote'))+" "),_c('v-spacer'),_c('v-btn',{staticClass:"d-none d-md-block",attrs:{"color":"primary"},on:{"click":function($event){_vm.isAddNewCheckNote = true}}},[_c('v-icon',{staticClass:"me-1"},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_vm._v(" "+_vm._s(_vm.$t('add_suggestion'))+" ")],1),_c('v-btn',{staticClass:"d-block d-md-none",attrs:{"color":"primary","fab":"","icon":"","outlined":""},on:{"click":function($event){_vm.isAddNewCheckNote = true}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)],1),_c('v-row',{staticClass:"px-2 mb-2"},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('search'),"outlined":"","dense":"","hide-details":""},model:{value:(_vm.searchtext),callback:function ($$v) {_vm.searchtext=$$v},expression:"searchtext"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.dataTableList,"options":_vm.options,"server-items-length":_vm.totalDataTableList,"footer-props":_vm.footer,"loading":_vm.loading,"disable-sort":"","hide-default-footer":"","loading-text":_vm.$t('data_loading'),"no-data-text":_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.note_id",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.segmentId + index + 1)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.dataEdit = item; _vm.isEditCheckNote = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPencilOutline))])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")]),_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.note_id = item.note_id;_vm.isDelete=true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteOutline))])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")])]}}],null,true)}),_c('v-divider'),_c('Pagination',{attrs:{"page-data":_vm.options.page,"page-count":_vm.totalPage,"segment-id":+_vm.segmentId,"count-list":_vm.dataTableList.length,"total":+_vm.totalDataTableList},on:{"pageChanged":function (page){_vm.options.page = page ; _vm.fetchDataTable()},"itemChanged":function (items){_vm.options.itemsPerPage = items;_vm.options.page=1 ; _vm.fetchDataTable()}}})],1),_c('AddNewCheckNote',{on:{"onAdd":function($event){return _vm.fetchDataTable('add')}},model:{value:(_vm.isAddNewCheckNote),callback:function ($$v) {_vm.isAddNewCheckNote=$$v},expression:"isAddNewCheckNote"}}),_c('EditCheckNote',{attrs:{"data-edit":_vm.dataEdit},on:{"onUpdate":function($event){return _vm.fetchDataTable('update')}},model:{value:(_vm.isEditCheckNote),callback:function ($$v) {_vm.isEditCheckNote=$$v},expression:"isEditCheckNote"}}),_c('RemoveCheckNote',{attrs:{"id":_vm.note_id},on:{"onDelete":function($event){return _vm.fetchDataTable('delete')}},model:{value:(_vm.isDelete),callback:function ($$v) {_vm.isDelete=$$v},expression:"isDelete"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }