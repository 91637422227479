<template>
  <div>
    <v-dialog
      v-model="isEditCheckNote"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('edit_suggestion') }}
        </v-card-title>
        <v-form
          ref="formEditCheckNote"
          @submit.prevent="updateCheckNote"
        >
          <v-card-text>
            <v-textarea
              v-model="dataEditLocal.note_name"
              autofocus
              rows="3"
              :label="$t('suggestion')"
              :rules="[required]"
              outlined
              dense
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-edit-check-note', false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import checkNote from '@/api/examination/checkNote'
import store from '@/store'

export default {
  model: {
    prop: 'isEditCheckNote',
    event: 'update:is-edit-check-note',
  },
  props: {
    isEditCheckNote: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const formEditCheckNote = ref(false)
    const dataEditLocal = ref({})
    const loading = ref(false)

    const { checkNoteUpdate } = checkNote

    const updateCheckNote = () => {
      const isFormValid = formEditCheckNote.value.validate()
      if (!isFormValid) return
      loading.value = true
      checkNoteUpdate(dataEditLocal.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-edit-check-note', false)
        loading.value = false
        emit('onUpdate')
      })
    }

    watch(() => props.dataEdit, val => {
      if (val) {
        dataEditLocal.value = JSON.parse(JSON.stringify(val))
      }
    })

    return {
      loading,
      formEditCheckNote,
      dataEditLocal,
      required,
      updateCheckNote,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
